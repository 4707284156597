import React from 'react';

import SEO from '../../components/seo';
import StoreContent from '../../components/store-content';

const StorePage = ({ data }) => (
	<>
		<SEO
			title='Store'
			path='/store/'
			description='Browse our selection of featured jewelry pieces available for purchase. Become a member to get 25% off our store!'
		/>
		<StoreContent
			title='Featured'
			items={data.allShopifyProduct.edges}
			includeViewAll={true}
		/>
	</>
);

export const query = graphql`
    query {
        allShopifyProduct(
            filter: {
                vendor: { eq: "Rose Gypsy" }
            }
            sort: {
            	fields: [title]
            	order: [ASC]
            }
        ) {
            edges {
                node {
                    id
                    title
                    handle
                    productType
                    vendor
                    shopifyId
                    createdAt
                    images {
                        id
                        originalSrc
                        localFile {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 680) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    variants {
                        shopifyId
                        price
                    }
                }
            }
        }
    }
`;

export default StorePage;
